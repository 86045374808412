<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="card-title px-4 mt-1">
                        Filters
                        <v-btn v-if="!isDealer" class="my-2 mx-2" dark small color="#027a00" @click="createTransaction">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <filters-alternative
                            :sort-options="headers.filter(element => element.sortable)"
                            :filters="filterFields"
                            @reloadFilters="reloadFilters"
                            @filterResults="filterResults"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-data-table
            item-key="id"
            :headers="headers"
            :items="transactions"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            class="elevation-1"
        >
            <template slot="item" slot-scope="props">
                <tr>
                    <td @click="handleClick('show', props.item)">
                        <v-icon v-if="props.item" :color="props.item.scanCompleted? 'green' : 'red'">
                            {{ props.item.scanCompleted ? 'mdi-check-outline' : 'mdi-close-outline' }}
                        </v-icon>
                        <v-icon v-else :color="'red'">
                            {{ 'mdi-close-outline' }}
                        </v-icon>
                    </td>
                    <td v-if="!isDealer" @click="handleClick('show', props.item)">{{ props.item.member.firstName + ' ' + props.item.member.lastName }}</td>
                    <td @click="handleClick('show', props.item)">
                        <div v-for="(card, index) in props.item.member.cards.filter(card => card.active && card.type==='PARON')" :key="index">
                            <span> {{  `${card.number}` }}</span>
                        </div>
                    </td>
                    <td @click="handleClick('show', props.item)">{{ props.item.partner ? props.item.partner.title : '' }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.partner ? props.item.partner.category : '' }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.receipt? formatDate(props.item.receipt.date, 'DD/MM/yyyy') : '' }}</td>
                    <td @click="handleClick('show', props.item)">{{ props.item.receipt? `${props.item.receipt.total} €` : '' }}</td>
                    <!-- <td @click="handleClick('show', props.item)">{{ props.item.description }}</td> -->
                    <td v-if="!isDealer">
                        <div class="buttons">
                            <v-btn class="mx-2" dark small color="#004cff" @click="handleClick('edit', props.item)">
                                <v-icon dark>mdi-account-edit</v-icon>
                            </v-btn>
                            <delete-button @delete-entry="onDelete(props.item)" />
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="7"
        />
    </v-container>
</template>

<script>
    import transactionService from '@/services/transaction-service'
    import DeleteButton from '@/components/DeleteButton'
    import moment from 'moment'
    import FiltersAlternative from '@/components/FiltersAlternative'
    import store from '@/store'

    export default {
        name: 'TransactionRoute',
        components: { DeleteButton, FiltersAlternative },
        data: () => {
            return {
                transactions: [],
                filterFields: [
                    {
                        text: 'Member',
                        value: 'member',
                        type: 'api',
                        data: null,
                        searchable: true
                    },
                    {
                        text: 'Partner',
                        value: 'partner',
                        type: 'api',
                        data: null,
                        searchable: true
                    },
                    {
                        text: 'Card',
                        value: 'card',
                        type: 'api',
                        data: null,
                        searchable: false
                    },
                    {
                        text: 'Scan Completed',
                        value: 'scanCompleted',
                        type: 'boolean',
                        searchable: true,
                        data: [true, false]
                    }
                ],
                headers: [{ text: 'ScanCompleted', value: 'scanCompleted' },
                          { text: 'Member', value: 'member' },
                          { text: 'Card', value: 'card' },
                          { text: 'Partner', value: 'partner' },
                          { text: 'Category', value: 'category' },
                          { text: 'Transaction Date', value: '' },
                          { text: 'Total', value: 'total' },
                          { text: 'Description', value: 'description' }
                ],
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                loading: true,
                sortOption: null,
                order: null,
                filters: null
            }
        },
        computed: {
            isDealer() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer'
            }
        },
        watch: {
            page() {
                var path = `/transactions?page=${this.page}`
                if (this.$route.fullPath !== path) {
                    this.$router.push(path)
                }
                this.fetchFilteredTransactions()
            },
            $route(to, from) {
                if (to !== from) {
                    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
                    this.fetchFilteredTransactions()
                }
            }
        },
        created() {
            this.page = this.$route.query.page ? parseInt(this.$route.query.page) : this.page
            this.filters = this.$store.getters.getFilters
            this.sortOption = this.$store.getters.getSortOption
            this.order = this.$store.getters.getSortOrder
            if (store.getters.currentUser.role === 'dealer') {
                this.headers = [
                    { text: 'Card', value: 'card' },
                    { text: 'Partner', value: 'partner' },
                    { text: 'Category', value: 'category' },
                    { text: 'Transaction Date', value: '' },
                    { text: 'Total', value: 'total' }
                ]
            }
        },
        methods: {
            reloadFilters() {
                this.order = 'ASC'
                this.sortOption = null
                this.filters = {}
                this.fetchFilteredTransactions()
            },
            filterResults(filterObject, sortOption = null, order = 'ASC', page = false) {
                this.sortOption = sortOption
                this.order = order
                this.filters = filterObject
                this.loading = true
                if (page) {
                    this.page = 1
                }
                transactionService.getTransactionsByFilter(this.page, filterObject, sortOption, order).then(resp => {
                    if (this.page > this.pageCount) {
                        this.page = 1
                    }
                    this.pageCount = resp.data.last_page
                    this.transactions = resp.data.data
                    this.loading = false
                })
            },
            handleClick(mode, item) {
                this.$router.push(`/transactions/${item.id}/${mode}?page=${this.page}`)
            },
            onDelete(item) {
                transactionService.deleteTransactionById(item.id).then(() => {
                    this.fetchFilteredTransactions()
                })
            },
            createTransaction() {
                this.$router.push(`/transactions/create?page=${this.page}`)
            },
            fetchFilteredTransactions() {
                this.loading = true
                transactionService.getTransactionsByFilter(this.page, this.filters, this.sortOption, this.order).then(resp => {
                    this.transactions = resp.data.data
                    this.pageCount = resp.data.last_page
                    this.loading = false
                })
            },
            formatDate(date, format) {
                return moment(date).format(format)
            }
        }
    }
</script>

<style scoped>
.search {
    max-width: 300px;
}
.table-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
td {
    min-width: 150px;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
</style>
